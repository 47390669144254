import roll_audio from "@/assets/sounds/roll.mp3"
import winning_audio from "@/assets/sounds/winning.mp3"

const rollAudio = new Audio(roll_audio)
const winningAudio = new Audio(winning_audio)

export const formatRouletteHistory = (history) => {
  return history.map((h) => {
    let icon = "bonus";
    if (h.outcome >= 1 && h.outcome <= 7) icon = "ct";
    else if (h.outcome >= 8 && h.outcome <= 14) icon = "t";
    return {
      id: h.id,
      icon,
      hash: h.hash,
    };
  });
};

export const playRollSound = async () => {
  try {
    rollAudio.volume = .5
    await rollAudio.play()
  } catch (e) { }
}

export const playWinningSound = async () => {
  try {
    winningAudio.volume = .5
    await winningAudio.play()
  } catch (e) { }
}