<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">Authentication</h3>
    </template>
    <template v-slot:body>
      <div v-if="loginShow">
        <div class="w-full">
          <div class="flex flex-col items-center mb-6">
            <div>
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Username
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text" v-model="login.username" @keypress.enter="Login" placeholder="e.g: XFlip" />
            </div>
            <div class="mt-5">
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Your Password
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="password" v-model="login.password" @keypress.enter="LoginNow" />
                <small class="text-gray-200 flex justify-center">Did you forget your password? <span class="ml-2 cursor-pointer text-blue-600 font-bold" @click="openForgotPasswordModal">Click here</span>!</small>
            </div>
          </div>
        </div>
        <small class="text-gray-400">
          By signing in you confirm that you are 18 years of age or over, of
          sound mind capable of taking responsibility for your own actions & are
          in proper jurisdiction, and have read and agreed to our
          <span class="font-bold text-blue-600 cursor-pointer" @click="showTos">terms of service</span>.
        </small>
      </div>
      <div v-else>
        <div class="w-full">
          <div class="flex flex-col items-center mb-6">
            <div>
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Username
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text" v-model="register.username" placeholder="e.g: XFlip" />
            </div>
            <div class="mt-5">
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Your Email
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="text" v-model="register.email" />
            </div>
            <div class="mt-5">
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Your Password
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="password" v-model="register.password" />
            </div>
            <div class="mt-5">
              <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
                Confirm your Password
              </label>
            </div>
            <div class="w-2/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                type="password" v-model="register.password2" @keypress.enter="RegisterNow" />
            </div>
          </div>
        </div>
        <small class="text-gray-400">
          By signing up you confirm that you are 18 years of age or over, of
          sound mind capable of taking responsibility for your own actions & are
          in proper jurisdiction, and have read and agreed to our
          <span class="font-bold text-blue-600 cursor-pointer" @click="showTos">terms of service</span>.
        </small>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row justify-between w-full">
        <button
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800"
          @click="showCreateAccount">
          Create Account
        </button>
        <button v-if="loginShow"
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          @click="LoginNow">
          Login
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ModalExampleVue",
  components: {
    Modal,
  },
  data() {
    return {
      loginShow: true,
      login: {
        username: "",
        password: "",
      },
      register: {
        username: "",
        email: "",
        password: "",
        password2: "",
      },
      modal: "registration",
    };
  },
  methods: {
    openForgotPasswordModal() {
      this.$store.dispatch("set_modal", { modal: "forgot_password", value: true });
    },
    showCreateAccount() {
      // if register page is already shown, register instead
      if (!this.loginShow) return this.RegisterNow();
      this.loginShow = false;
    },
    RegisterNow() {
      this.$store.dispatch("user_register", this.register);
    },
    LoginNow() {
      this.$store.dispatch("user_login", this.login);
    },
    showTos() {
      this.$store.dispatch("set_modal", { modal: "tos", value: true });
    },
  },
  watch: {
    show() {
      // in case user closes the registration modal, reset everything to default
      if (!this.$store.state.modals[this.modal]) {
        this.loginShow = true;
        this.login = {
          username: "",
          password: "",
        };
        this.register = {
          username: "",
          password: "",
          password2: "",
        };
      }
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>
