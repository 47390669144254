<template>
  <Navbar />
  <div class="main-wrapper flex flex-row">
    <Chat />
    <div class="w-full p-5 bg-gray-900 main-controls">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <!-- Modals -->
    <RegistrationModal />
    <TosModal />
    <FaqModal />
    <ChatRulesModal />
    <FreeCoinsModal />
    <SetEmailModal />
    <ForgotPasswordModal />
    <ResetPasswordModal />

    <SelectDepositMethodModal />
    <OSRSDepositModal />
    <CryptoDepositModal />
    <WithdrawModal />
  </div>
</template>

<script>
import "@/assets/css/global.css";

import Navbar from "@/components/Navbar.vue";
import Chat from "@/components/Chat.vue";

import RegistrationModal from "@/components/Global/RegistrationModal.vue";
import TosModal from "@/components/Global/TosModal.vue";
import FaqModal from "@/components/Global/FaqModal.vue";
import ChatRulesModal from "@/components/Global/ChatRulesModal.vue";
import FreeCoinsModal from "@/components/Global/FreeCoinsModal.vue";
import SetEmailModal from "@/components/Global/SetEmailModal.vue";
import ForgotPasswordModal from "@/components/Global/ForgotPasswordModal.vue";
import ResetPasswordModal from "./components/Global/ResetPasswordModal.vue";

import SelectDepositMethodModal from "@/components/Global/SelectDepositMethodModal.vue";
import OSRSDepositModal from "@/components/Global/OSRSDepositModal.vue";
import CryptoDepositModal from "@/components/Global/CryptoDepositModal.vue";
import WithdrawModal from "@/components/Global/WithdrawModal.vue";

export default {
  name: "MainApp",
  components: {
    Navbar,
    Chat,
    RegistrationModal,
    TosModal,
    FaqModal,
    ChatRulesModal,
    FreeCoinsModal,
    SetEmailModal,
    ForgotPasswordModal,
    ResetPasswordModal,

    SelectDepositMethodModal,
    OSRSDepositModal,
    CryptoDepositModal,
    WithdrawModal,
  },
  mounted() {
    this.$store.dispatch("init");
  },
};
</script>