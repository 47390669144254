<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">Select Deposit Method</h3>
    </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="flex flex-row justify-between w-full">
          <button
            class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
            @click="selectMethod('osrs')">
            OSRS Deposit
          </button>
          <button
            class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
            @click="selectMethod('crypto')">
            Crypto Deposit
          </button>
        </div>
      </div>
    </template>
    <template v-slot:footer></template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "SelectDepositMethodModal",
  components: {
    Modal,
  },
  data() {
    return {
      modal: "select_deposit_method",
    };
  },
  methods: {
    selectMethod(type) {
      if (type === 'osrs') this.$store.dispatch("set_modal", { modal: "osrs_deposit", value: true });
      else this.$store.dispatch("set_modal", { modal: "crypto_deposit", value: true });

      this.$store.dispatch("set_modal", { modal: "select_deposit_method", value: false });
    }
  },
  watch: {
    show() {
      // reset everything to default
      if (!this.$store.state.modals[this.modal]) {
      }
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
  },
};
</script>
