<template>
  <div class="gm-wrapper support">
    <div class="header">
      <div class="back" @click="back">Back</div>Create new support ticket
    </div>
    <div class="form">
      <div>
        <label>Subject</label>
        <input type="text" v-model="data.subject" />
      </div>
      <div>
        <label>Description</label>
        <textarea v-model="data.description"></textarea>
      </div>
      <div>
        <label>Priority</label>
        <select v-model="data.priority">
          <option value="lowest">Lowest</option>
          <option value="normal">Normal</option>
          <option value="highest">Highest</option>
        </select>
      </div>
    </div>
    <div class="create-support-ticket mt-2" @click="submit">Submit</div>
  </div>
</template>

<script>
import "@/assets/css/support.css"

export default {
  name: "SupportCreateComp",
  methods: {
    async submit() {
      const data = await this.$store.dispatch('user_support_create', this.data)
      if (data?.id) this.$router.push(`/support/view/${data.id}`)
    },
    back() {
      this.$router.push('/support')
    }
  },
  data() {
    return {
      data: {
        subject: '',
        description: '',
        priority: 'normal'
      }
    }
  }
}
</script>