<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">
        <font-awesome-icon icon="fa-solid fa-plus-square" />&nbsp;Crypto Deposit
      </h3>
    </template>
    <template v-slot:body>
      <div class="text-white">
        <div class="w-full">
          <div v-if="!method_selected" class="flex flex-col items-center mb-6 methods">
            <div @click="change_method(method)" class="method" v-for="(method, index) in methods" :key="index">
              <img :src="env.assets_url +
                '/methods/' +
                method.coin.toLowerCase() +
                '.png'
                " :alt="method.coin" />
              <div v-html="method.name"></div>
            </div>
            <small class="font-bold mt-5">Note: After selecting a deposit method, your unique and permanent
              address will be generated for the specific currency.</small>
          </div>

          <div v-if="method_selected" class="flex flex-col items-center mb-6">
            <div v-if="!deposit_data.address" class="anim">
              <svg class="circle-svg" height="200" width="200">
                <circle cx="100" cy="100" r="50"></circle>
              </svg>
            </div>
            <div class="flex flex-col flex-wrap" v-else>
              <div class="w-100 flex justify-center qrcode">
                <img v-if="deposit_data.address" :src="'https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=' +
                  deposit_data.address
                  " />
              </div>
              <div v-if="deposit_data.address" class="mt-5 flex flex-col justify-center items-center">
                <div>
                  <label class="flex flex-row items-center text-gray-300 font-bold text-right mb-2 pr-4">
                    <img class="mr-1" style="width: 20px; height: 20px" :src="env.assets_url +
                      '/methods/' +
                      method_selected.coin.toLowerCase() +
                      '.png'
                      " :alt="method_selected.coin" />
                    Your {{ method_selected.name }}&nbsp;Address:
                  </label>
                </div>
                <div class="w-2/4">
                  <input @input="no_edit" @click="copy_address(deposit_data.address)"
                    class="cursor-copy bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    :value="deposit_data.address" />
                </div>
              </div>
              <small class="font-bold mt-5">
                Note: Deposit any amount to this
                {{ deposit_data.currency }} address. Contracts or any other
                network than {{ deposit_data.currency }} will not be credited!
              </small>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row justify-start w-full">
        <button v-if="method_selected" @click="back" type="button"
          class="mr-5 flex justify-center items-center mt-5 text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-green-600 hover:bg-green-700 focus:ring-green-800">
          <div>Back</div>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { Toast } from "@/services/toast.js";

export default {
  name: "CryptoDepositModal",
  components: {
    Modal,
  },
  data() {
    return {
      method_selected: false,
      modal: "crypto_deposit",
    };
  },
  methods: {
    async copy_address(addr) {
      try {
        await navigator.clipboard.writeText(addr);
        Toast("success", "Address copied!");
      } catch (e) { }
    },
    change_method(method) {
      this.method_selected = method;

      // request generation of selected method
      this.$store.dispatch("user_generate_address", this.method_selected.coin);
    },
    no_edit(e) {
      e.target.value = this.deposit_data.address;
      return false;
    },
    back() {
      this.method_selected = false;
      this.$store.commit("set_deposit_data", { address: "", currency: "" });
    },
  },
  watch: {
    show() {
      // reset everything if modal closes
      if (!this.$store.state.modals[this.modal]) {
        this.method_selected = false;
        this.$store.commit("set_deposit_data", { address: "", currency: "" });
      }
    }
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
    env() {
      return this.$store.state.env;
    },
    deposit_data() {
      return this.$store.state.website.deposit;
    },
    methods() {
      return this.$store.state.website.crypto_methods;
    }
  }
};
</script>
