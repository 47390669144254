<template>
  <div class="gm-wrapper support">
    <div class="header" v-if="user.rank == 100">Admin Support</div>
    <div class="header" v-else>Support</div>
    <div class="create-support-ticket" v-if="user.rank !== 100" @click="create">Create a support ticket</div>
    <div class="header mt-5" v-if="user.rank !== 100">Your support tickets:</div>
    <div class="header mt-5" v-else>All support tickets:</div>
    <div class="support-tickets">
      <div v-for="ticket in support_tickets" :key="ticket.id">
        <div>#{{ ticket.id }}</div>
        <div v-if="user.rank == 100"><img class="tavatar" :src="ticket.user.avatar" /><span class="tusername"
            v-html="ticket.user.username"></span> {{ ticket.subject }}</div>
        <div v-else>{{ ticket.subject }}</div>
        <div><small>created:</small> {{ new Date(Date.now() - ticket.time).toLocaleString() }}</div>
        <div><small>status:</small> {{ ticket.status === 0 ? 'pending' : ticket.status === 1 ? 'answered' : 'closed' }}
        </div>
        <div @click="viewTicket(ticket.id)">View</div>
        <div @click="closeTicket(ticket.id)">Close</div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/support.css"

export default {
  name: "SupportComp",
  methods: {
    create() {
      this.$router.push('/support/create')
    },
    viewTicket(ticket_id) {
      this.$router.push(`/support/view/${ticket_id}`)
    },
    closeTicket(ticket_id) {
      this.$store.dispatch('user_close_ticket', ticket_id)
    }
  },
  computed: {
    support_tickets() {
      return this.$store.state.user_support_tickets
    },
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.$store.dispatch("user_support_tickets")
  }
}
</script>