<template>
  <div class="gm-wrapper support">
    <div class="back" @click="back">Back</div>
    <div class="sticket">
      <div class="subject">
        <div>{{ support.ticket.subject }}</div>
        <div>Priority: <span>{{ support.ticket.priority }}</span> | Created: {{ new
          Date(support.ticket.time).toLocaleString() }}</div>
      </div>
      <div class="description" v-html="ticketDescription()"></div>
    </div>
    <div class="smessages">
      <div v-for="msg in support.messages" :key="msg.id" :class="msg.is_admin ? 'is_admin' : ''">
        <div class="user">
          <div>
            <img :src="msg.user.avatar" alt="Avatar">
            <div><small v-if="msg.is_admin">ADMIN</small> {{ msg.user.username }}</div>
          </div>
          <div>{{ new Date(Date.now() - msg.time).toLocaleString() }}</div>
        </div>
        <div class="message" v-html="formatMessage(msg.message)"></div>
      </div>
    </div>
    <div class="swrite" v-if="support.ticket.status !== 2">
      <div>Submit an answer:</div>
      <textarea v-model="answer"></textarea>
      <div class="answer_btn" @click="send">Send</div>
    </div>
    <div v-else class="swrite">
      <div style="color: red;">Support ticket is closed</div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/support.css"

export default {
  name: "SupportComp",
  methods: {
    ticketDescription() {
      return this.support.ticket.description.split('\n').join('<br/>')
    },
    formatMessage(msg) {
      return msg.split('\n').join('<br/>')
    },
    back() {
      this.$router.push('/support')
    },
    async send() {
      await this.$store.dispatch('user_support_send', this.answer)
      this.$store.dispatch('user_load_support_ticket', this.support.ticket.id)
      this.answer = ''
    }
  },
  data() {
    return {
      answer: ''
    }
  },
  computed: {
    support() {
      return this.$store.state.current_support_ticket
    }
  }
}
</script>