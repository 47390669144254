<template>
  <Modal :show="show" :modal="modal">
    <template v-slot:header>
      <h3 class="text-xl font-semibold text-white">Reset Password</h3>
    </template>
    <template v-slot:body>
      <div class="w-full">
        <div class="flex flex-col items-center mb-6 mt-5">
          <div>
            <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
              Your Username
            </label>
          </div>
          <div class="w-2/4">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text" v-model="forgot_password.username" />
          </div>
          <div class="mt-5">
            <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
              Your Email
            </label>
          </div>
          <div class="w-2/4">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text" v-model="forgot_password.email" />
          </div>
          <div class="mt-5">
            <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
              Reset Password Token
            </label>
          </div>
          <div class="w-2/4">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="text" v-model="forgot_password.token" />
          </div>
          <div class="mt-5">
            <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
              Your new Password
            </label>
          </div>
          <div class="w-2/4">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="password" v-model="data.password" />
          </div>
          <div class="mt-5">
            <label class="block text-gray-300 font-bold text-right mb-2 pr-4">
              Confirm Your new Password
            </label>
          </div>
          <div class="w-2/4">
            <input
              class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
              type="password" v-model="data.confirm_password" />
          </div>
        </div>
        <small class="text-gray-400">
          After clicking "Set Password" the password will be changed.
        </small>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex flex-row justify-between w-full">
        <button
          class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
          @click="setPassword">
          Set Password
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ResetPasswordModal",
  components: {
    Modal,
  },
  data() {
    return {
      data: {
        password: '',
        confirm_password: ''
      },
      modal: "reset_password",
    };
  },
  methods: {
    setPassword() {
      this.$store.dispatch('user_reset_password', {
        username: this.forgot_password.username,
        email: this.forgot_password.email,
        token: this.forgot_password.token,
        ...this.data
      })
    }
  },
  watch: {
    show() {
      // in case user closes the set_email modal, reset everything to default
      if (!this.$store.state.modals[this.modal]) {
        this.data.password = ''
        this.data.confirm_password = ''
        this.$store.state.website.forgot_password.username = ''
        this.$store.state.website.forgot_password.email = ''
        this.$store.state.website.forgot_password.token = ''
      }
    },
  },
  computed: {
    show() {
      return this.$store.state.modals[this.modal];
    },
    forgot_password() {
      return this.$store.state.website.forgot_password;
    }
  },
};
</script>
