import axios from "axios";

import { createStore } from "vuex";

import io from "socket.io-client";
import cookie from "vue-cookies";

// Internal imports
import { Toast } from "@/services/toast";

import { formatRouletteHistory, playRollSound, playWinningSound } from "@/store/helpers/roulette";
import { createCaseTargetID } from "@/store/helpers/cases";

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const ASSETS_URL = process.env.VUE_APP_ASSETS_URL;

const store = createStore({
  state: {
    env: {
      backend_url: BACKEND_URL,
      assets_url: ASSETS_URL,
    },

    socket: null,

    website: {
      players: 0,
      chat: {
        messages: [],
      },
      games: {
        roulette: {
          id: null,
          connected: false,
          secret: "",
          seconds: 0,
          state: 0,
          bets: [],
          history: [],
          last_rolls: {
            ct: 0,
            bonus: 0,
            t: 0,
          },
          interval: null,
          animation: {
            "transition-timing-function": "",
            "transition-duration": "",
            transform: "",
          },
        },
        minesweeper: {
          state: "idle",
          amount: 0,
          default_tiles: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0,
          ],
          tiles: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0,
          ],
          sounds: {
            boom: new Audio(`${ASSETS_URL}/sounds/boom.mp3`),
            click: new Audio(`${ASSETS_URL}/sounds/click.mp3`),
          },
        },
        cases: {
          boxes: [],
          current_case: null,
        },
      },
      deposit: {
        address: "",
        currency: "",
      },
      crypto_methods: [],
      forgot_password: {}
    },

    // should not be added default values, it will be created once modals are opened/closed
    modals: {},

    user: {},
    admin: {
      deposits: [],
      withdraws: []
    },
    active_referrers: {},
    user_support_tickets: [],
    current_support_ticket: {},

    session_token: "",
    loggedin: false,
  },
  mutations: {
    set_socket(state, props) {
      state.socket = props;
    },

    set_modal(state, props) {
      state.modals[props.modal] = props.value;
    },

    set_chat_messages(state, props) {
      state.website.chat.messages = props;
    },

    set_deposit_data(state, props) {
      state.website.deposit.address = props.address;
      state.website.deposit.currency = props.currency;
    },

    set_active_referrers(state, refs) {
      state.active_referrers = refs;
    },

    loggedin(state, user) {
      state.user = user;
      console.log(state.user);
      state.loggedin = true;
    },

    logout(state) {
      state.user = {};
      state.loggedin = false;
    },

    user(state, payload) {
      if (payload.type === "update_balance") {
        state.user.points = payload.balance;
      }

      if (payload.type === "update_aff_code") {
        state.user.aff_code = payload.aff_code;
      }

      if (payload.type === "clear_earnings") {
        state.user.aff_earnings = 0;
      }
    },

    roulette(state, payload) {
      if (payload.type === "spin_wheel") {
        state.website.games.roulette.animation = payload.css;
      }

      if (payload.type === "seconds") {
        state.website.games.roulette.seconds = payload.seconds;
      }

      if (payload.type === "interval") {
        state.website.games.roulette.interval = payload.interval;
      }

      if (payload.type === "prepare_spin") {
        state.website.games.roulette.seconds = 0;
        state.website.games.roulette.state = 1;
      }

      if (payload.type === "reset") {
        state.website.games.roulette.seconds =
          state.website.games.roulette.default_seconds;
        state.website.games.roulette.state = 0;
        state.website.games.roulette.bets = [];
      }

      if (payload.type === "bets") {
        state.website.games.roulette.bets = payload.bets;
      }

      if (payload.type === "bet") {
        state.website.games.roulette.bets.push(payload.bet);
      }

      if (payload.type === "history") {
        state.website.games.roulette.history = formatRouletteHistory(
          payload.history
        );
      }

      if (payload.type === "connected") {
        if (!state.website.games.roulette.connected)
          state.website.games.roulette.connected = true;
      }

      if (payload.type === "last_rolls") {
        state.website.games.roulette.last_rolls.ct = payload.rolls.filter(
          (i) => {
            return i >= 1 && i <= 7;
          }
        ).length;
        state.website.games.roulette.last_rolls.bonus = payload.rolls.filter(
          (i) => {
            return i == 0;
          }
        ).length;
        state.website.games.roulette.last_rolls.t = payload.rolls.filter(
          (i) => {
            return i >= 8 && i <= 14;
          }
        ).length;
      }

      if (payload.type === "game_info") {
        state.website.games.roulette.secret = payload.secret;
        state.website.games.roulette.id = payload.id;
      }
    },

    minesweeper(state, payload) {
      if (payload.type === "game") {
        state.website.games.minesweeper.state = "in_game";
        state.website.games.minesweeper.tiles = payload.data.tiles;
        state.website.games.minesweeper.amount = payload.data.current_amount;

        if (payload.data.type === "new_tile")
          state.website.games.minesweeper.sounds.click.play();
      }

      if (payload.type === "reset") {
        state.website.games.minesweeper.state = "idle";
        state.website.games.minesweeper.tiles =
          state.website.games.minesweeper.default_tiles;
        state.website.games.minesweeper.amount = 0;
      }

      if (payload.type === "round_lost") {
        state.website.games.minesweeper.state = "idle";
        for (let x in payload.data) {
          const tile_index = parseInt(payload.data[x]) - 1;
          state.website.games.minesweeper.tiles[tile_index] = -1;
        }
      }

      if (payload.type === "show_mines") {
        state.website.games.minesweeper.state = "idle";
        for (let x in payload.data) {
          const tile_index = parseInt(payload.data[x]) - 1;
          state.website.games.minesweeper.tiles[tile_index] = -1;
        }
      }
    },

    players(state, players) {
      state.website.players = players;
    },
  },
  actions: {
    roulette({ state, commit }, props) {
      let order;
      let position;
      let rows;
      let object;
      let card;
      let landingPosition;

      if (props.type === "spin_wheel") {
        (order = [0, 11, 5, 10, 6, 9, 7, 8, 1, 14, 2, 13, 3, 12, 4]),
          (position = order.indexOf(props.outcome));

        (rows = 4),
          (card = 100 + 3 * 2),
          (landingPosition = rows * 15 * card + position * card);

        object = {
          x: Math.floor(Math.random() * 50) / 100,
          y: Math.floor(Math.random() * 20) / 100,
        };

        commit("roulette", {
          type: "spin_wheel",
          css: {
            "transition-timing-function":
              "cubic-bezier(0," + object.x + "," + object.y + ",1)",
            "transition-duration": "6s",
            transform: "translate3d(-" + landingPosition + "px, 0px, 0px)",
          },
        });
      }

      if (props.type === "fix_spin_wheel") {
        (order = [0, 11, 5, 10, 6, 9, 7, 8, 1, 14, 2, 13, 3, 12, 4]),
          (position = order.indexOf(props.outcome));

        (rows = 4),
          (card = 100 + 3 * 2),
          (landingPosition = rows * 15 * card + position * card);

        object = {
          x: Math.floor(Math.random() * 50) / 100,
          y: Math.floor(Math.random() * 20) / 100,
        };

        commit("roulette", {
          type: "spin_wheel",
          css: {
            "transition-timing-function":
              "cubic-bezier(0," + object.x + "," + object.y + ",1)",
            "transition-duration": "0s",
            transform: "translate3d(-" + landingPosition + "px, 0px, 0px)",
          },
        });
      }

      if (props.type === "place_bet") {
        if (!state.loggedin) return Toast("error", "You are not signed in!");
        state.socket.emit("roulette", {
          type: "place bet",
          amount: props.amount,
          coin: props.coin,
        });
      }

      if (props.type === "reset") {
        commit("roulette", {
          type: "reset",
        });

        object = {
          x: Math.floor(Math.random() * 50) / 100,
          y: Math.floor(Math.random() * 20) / 100,
        };

        commit("roulette", {
          type: "spin_wheel",
          css: {
            "transition-timing-function":
              "cubic-bezier(0," + object.x + "," + object.y + ",1)",
            "transition-duration": "3.5s",
            transform: "translate3d(0px, 0px, 0px)",
          },
        });
      }

      if (props.type === "timer") {
        let seconds = props.seconds;
        clearInterval(state.website.games.roulette.interval);
        let interval = setInterval(() => {
          seconds = seconds - 1;
          commit("roulette", {
            type: "seconds",
            seconds,
          });
          if (seconds <= 0) clearInterval(interval);
        }, 10);
        commit("roulette", {
          type: "interval",
          interval,
        });
      }
    },

    minesweeper({ state, commit }, props) {
      if (props.type === "game") {
        commit("minesweeper", {
          type: "game",
          data: props.data,
        });
      }

      if (props.type === "round_lost") {
        commit("minesweeper", {
          type: "round_lost",
          data: props.data,
        });

        // play boom sound
        state.website.games.minesweeper.sounds.boom.play();
      }

      if (props.type === "show_mines") {
        commit("minesweeper", {
          type: "show_mines",
          data: props.data,
        });
      }

      if (props.type === "discover_tile") {
        if (!state.loggedin) return Toast("error", "You are not signed in!");

        if (state.website.games.minesweeper.state !== "idle") {
          state.socket.emit("minesweeper", {
            type: "discover tile",
            tile: props.tile,
          });
        }
      }

      if (props.type === "place_bet") {
        if (!state.loggedin) return Toast("error", "You are not signed in!");

        if (state.website.games.minesweeper.state === "idle") {
          state.socket.emit("minesweeper", {
            type: "place bet",
            amount: props.amount,
            mines: props.mines,
          });
        } else {
          state.socket.emit("minesweeper", {
            type: "cashout",
          });
        }
      }

      if (props.type === "reset") {
        commit("minesweeper", {
          type: "reset",
        });
      }
    },

    set_chat_messages({ commit }, messages) {
      commit("set_chat_messages", messages);
    },

    add_chat_message({ state, commit }, message) {
      let messages = state.website.chat.messages;
      messages.push(message);
      commit("set_chat_messages", messages);
    },

    set_modal({ commit }, { modal, value }) {
      commit("set_modal", {
        modal,
        value,
      });
    },

    // ADMIN ONLY
    async admin({ state }, props) {
      if (props.type === 'credit') {
        const data = await backendRequest('admin', 'credit', {
          session_token: cookie.get("session_token"),
          rid: props.rid
        })

        if (data.status === 400)
          return Toast('error', 'Error')
        if (data.status === 500) return Toast("error", "Internal Server Error");
        if (data?.error) return Toast("error", data.error);

        Toast('success', 'Successfully credited the user with coins!')

        state.admin.deposits = state.admin.deposits.map(deposit => {
          if (deposit.id === props.rid) deposit.state = 1
          return deposit
        })
      } else if (props.type === 'cancel') {
        const data = await backendRequest('admin', 'set-status', {
          session_token: cookie.get("session_token"),
          rid: props.rid,
          status: 2
        })

        if (data.status === 400)
          return Toast('error', 'Error')
        if (data.status === 500) return Toast("error", "Internal Server Error");
        if (data?.error) return Toast("error", data.error);

        Toast('success', 'Successfully cancelled the transaction!')

        state.admin[props.tip] = state.admin[props.tip].map(t => {
          if (t.id === props.rid) t.state = 2
          return t
        })
      } else if (props.type === 'process') {
        const data = await backendRequest('admin', 'set-status', {
          session_token: cookie.get("session_token"),
          rid: props.rid,
          status: 1
        })

        if (data.status === 400)
          return Toast('error', 'Error')
        if (data.status === 500) return Toast("error", "Internal Server Error");
        if (data?.error) return Toast("error", data.error);

        Toast('success', 'Successfully processed the transaction!')

        state.admin.withdraws = state.admin.withdraws.map(withdraw => {
          if (withdraw.id === props.rid) withdraw.state = 1
          return withdraw
        })
      }
    },

    async admin_runescape({ state }) {
      const data = await backendRequest('admin', 'info', {
        session_token: cookie.get("session_token"),
      })

      if (data.status === 400)
        return Toast('error', 'Error')
      if (data.status === 500) return Toast("error", "Internal Server Error");
      if (data?.error) return Toast("error", data.error);

      state.admin = data
    },
    // 

    async request_withdraw({ dispatch }, {
      amount
    }) {
      const data = await backendRequest('user', 'request/withdraw', {
        session_token: cookie.get("session_token"),
        amount
      })


      if (data.status === 400)
        return Toast('error', 'Invalid amount!')

      if (data.status === 500) return Toast("error", "Internal Server Error");

      if (data?.error) return Toast("error", data.error);

      Toast("To finalize the withdraw process, please contact us via live chat!")

      dispatch("set_modal", {
        modal: "withdraw",
        value: false,
      });
    },

    async request_deposit({ dispatch }, {
      amount
    }) {
      const data = await backendRequest('user', 'request/deposit', {
        session_token: cookie.get("session_token"),
        amount
      })


      if (data.status === 400)
        return Toast('error', 'Invalid amount!')

      if (data.status === 500) return Toast("error", "Internal Server Error");

      if (data?.error) return Toast("error", data.error);

      Toast("success", "You have successfully requested to deposit, please contact us via live chat!")

      dispatch("set_modal", {
        modal: "deposit",
        value: false,
      });
    },

    async user_use_free_code({ commit }, code) {
      if (code.length === 0) return Toast("error", "Code not found!");

      let data = await backendRequest("user", "use_free_code", {
        session_token: cookie.get("session_token"),
        code,
      });
      if (data.status === 400)
        return Toast("error", "An unknown error ocurred, try again!");
      if (data.status === 500) return Toast("error", "Internal Server Error");
      if (data.error) return Toast("error", data.error);

      Toast("success", `You have successfully got ${data.points} coins!`);
    },

    async user_register({ dispatch }, details) {
      let { username, email, password, password2 } = details;

      if (password !== password2)
        return Toast("error", "Passwords are not equal!");

      const data = await backendRequest("auth", "create", {
        username,
        email,
        password,
      });
      if (data.status === 400)
        return Toast(
          "error",
          "An account with theese credentials already exists!"
        );
      if (data.status === 401)
        return Toast(
          "error",
          "The email is invalid or not accepted!"
        );
      if (data.status === 500) return Toast("error", "Internal Server Error");

      Toast("success", "You successfully created an account!");
      cookie.set("session_token", data.token);

      dispatch("set_modal", {
        modal: "registration",
        value: false,
      });

      dispatch("set_user_logon", data);
    },

    async user_withdraw({ commit }, details) {
      const data = await backendRequest("user", "withdraw", {
        session_token: cookie.get("session_token"),
        amount: details.amount,
        address: details.address,
        currency: details.currency,
      });

      if (data.status === 400 || data.status === 401)
        return Toast("error", "An unknown error ocurred, try again!");

      if (data.status === 500) return Toast("error", "Internal Server Error");

      if (data.error) return Toast("error", data.error);

      Toast(
        "success",
        `You have successfully initiated a withdraw of ${details.value} points!`
      );
    },

    async user_generate_address({ commit }, currency) {
      const data = await backendRequest("user", "generate_address", {
        session_token: cookie.get("session_token"),
        currency,
      });

      if (data.status === 400 || data.status === 401)
        return Toast("error", "An unknown error ocurred, try again!");

      if (data.status === 500) return Toast("error", "Internal Server Error");

      commit("set_deposit_data", {
        address: data.address,
        currency,
      });
    },

    async user_collect_earnings({ commit }) {
      const data = await backendRequest("user", "collect_earnings", {
        session_token: cookie.get("session_token"),
      });

      if (data.status === 500) return Toast("error", "Internal Server Error");

      if (data.status === 400)
        return Toast("error", "An unknown error ocurred, try again!");

      if (data.error) return Toast("error", data.error);

      commit("user", { type: "clear_earnings" });

      Toast("success", data.success);
    },

    // this emits an endpoint request to '/api/user' but the response is sent to socket
    user_get_active_referrers({ commit }) {
      backendRequest("user", "get_active_referrers", {
        session_token: cookie.get("session_token"),
      });
    },

    async user_update_referral_code({ commit }, code) {
      if (code.length < 5)
        return Toast("error", "The referral code cannot be this short!");

      if (code.length > 21)
        return Toast("error", "The referral code cannot be this long!");

      const data = await backendRequest("user", "update_referral_code", {
        session_token: cookie.get("session_token"),
        code,
      });

      if (data.status === 400)
        return Toast("error", "An unknown error ocurred, try again!");

      if (data.status === 500) return Toast("error", "Internal Server Error");

      if (data.error) return Toast("error", data.error);

      commit("user", { type: "update_aff_code", aff_code: code });

      Toast("success", data.success);
    },

    async user_login({ dispatch }, details) {
      let { username, password } = details;
      const data = await backendRequest("auth", "credentials", {
        username,
        password,
      });

      if (data.status === 404)
        return Toast("error", "This user does not exists!");

      if (data.status === 500) return Toast("error", "Internal Server Error");

      Toast("success", "You successfully logged in!");
      cookie.set("session_token", data.token);

      dispatch("set_modal", {
        modal: "registration",
        value: false,
      });

      dispatch("set_user_logon", data);
    },

    async user_set_email({ dispatch }, { email, email2 }) {
      if (email !== email2) return Toast("error", "Check the fields again, the emails doesn't matches!");

      const data = await backendRequest("user", "set_email", {
        email,
        session_token: cookie.get("session_token")
      })

      if (data.status === 400) return Toast("error", "Unknown error ocurred, try again!");
      if (data.status === 404) return Toast("error", "Could not update email!");

      if (data.status === 500) return Toast("error", "Internal Server Error");

      dispatch("set_modal", {
        modal: "set_email",
        value: false,
      });

      Toast("success", "You have successfully updated your email. Check your inbox (also Spam) to verify your account!")
    },

    async user_forgot_password({ dispatch }, { username, email }) {
      const data = await backendRequest("user", "forgot_password", {
        username,
        email,
        session_token: cookie.get('session_token')
      })

      if (data.status === 404 || data.status === 400) return Toast("error", "No account was found!")

      if (data.status === 500) return Toast("error", "Internal Server Error")

      dispatch("set_modal", {
        modal: "forgot_password",
        value: false
      })

      Toast("success", "A reset password email has been sent to you!")
    },

    async user_reset_password({ dispatch }, props) {
      if (props.password !== props.confirm_password)
        return Toast("error", "The passwords does not matches!")

      const data = await backendRequest("user", "reset_password", {
        username: props.username,
        email: props.email,
        token: props.token,
        password: props.password
      })

      if (data.status === 404) return Toast("error", "No account was found!")
      if (data.status === 500) return Toast("error", "Internal Server Error")

      dispatch("set_modal", {
        modal: "reset_password",
        value: false
      })

      Toast("success", "You have successfully reset your password! You can now login")
    },

    async user_close_ticket({ dispatch }, ticket_id) {
      if (!ticket_id) return Toast('error', 'Ticket not found!')

      const data = await backendRequest("support", "close", {
        session_token: cookie.get('session_token'),
        ticket_id
      })

      if (data.status === 400) return Toast("error", "Something happened, refresh and try again!")
      if (data.status === 403) return Toast("error", "Ticket already closed!")
      if (data.status === 404) return Toast("error", "Ticket not found!")
      if (data.status === 500) return Toast("error", "Internal Server Error")

      Toast('success', 'Ticket successfully closed!')
      dispatch('user_support_tickets')
    },

    async user_support_send({ commit }, answer) {
      if (answer.length < 3) return Toast('error', 'Answer is too short!')

      const data = await backendRequest("support", "answer", {
        session_token: cookie.get('session_token'),
        ticket_id: this.state.current_support_ticket.ticket.id,
        answer
      })

      if (data.status === 400) return Toast("error", "Something happened, refresh and try again!")
      if (data.status === 403) return Toast("error", "Ticket is closed!")
      if (data.status === 404) return Toast("error", "Ticket not found!")
      if (data.status === 500) return Toast("error", "Internal Server Error")
    },

    async user_support_create({ commit }, props) {
      if (props.subject.length < 3) return Toast('error', 'Subject is too short!')
      const valid_priorities = ['lowest', 'normal', 'highest']
      if (valid_priorities.indexOf(props.priority) === -1) return Toast('error', 'Something happened, refresh and try again!')

      const data = await backendRequest("support", "create", {
        session_token: cookie.get('session_token'),
        subject: props.subject,
        description: props.description,
        priority: props.priority
      })

      if (data.status === 400) return Toast("error", "Something happened, refresh and try again!")
      if (data.status === 401) return Toast('error', "You can have up to 2 support tickets opened!")
      if (data.status === 500) return Toast("error", "Internal Server Error")

      Toast('success', 'Support ticket successfully created!')

      return data
    },

    async user_support_tickets({ state }) {
      const data = await backendRequest(`support?session_token=${cookie.get('session_token')}`, null, {}, "get")

      if (data.status === 400 || data.status === 404) return console.error("User not found!")
      if (data.status === 500) return console.error('Internal Server Error')

      const support_tickets = data.sort((a, b) => {
        return b.id - a.id
      })
      state.user_support_tickets = support_tickets
    },

    async user_load_support_ticket({ state }, ticket_id) {
      const data = await backendRequest("support", "ticket", {
        session_token: cookie.get('session_token'),
        ticket_id
      })

      if (data.status === 400 || data.status === 404) {
        console.error("User or ticket not found!")
        return false
      }
      if (data.status === 500) {
        console.error('Internal Server Error')
        return false
      }

      state.current_support_ticket = data
      return true
    },

    async get_user_logged_in({ commit }, session_token) {
      return await backendRequest("auth", "token", {
        session_token,
      });
    },

    async load_methods({ state }) {
      const data = (await backendRequest("oxapay", "", {}, "get")).map((m) => {
        return {
          coin: m,
          name: m,
        };
      });

      state.website.crypto_methods = data;
    },

    async load_cases({ state }) {
      const data = await backendRequest("cases", "", {}, "get");
      const boxes = data.map(i => createCaseTargetID(i))

      state.website.games.cases.boxes = boxes;
    },

    set_case({ state }, case_id) {
      state.website.games.cases.current_case = case_id;
    },

    logout({ commit }) {
      commit("logout");
      cookie.remove("session_token");
    },

    send_message({ state }, message) {
      if (!state.loggedin) return Toast("error", "You are not signed in!");
      state.socket.emit("chat", message);
    },

    set_user_logon({ state, commit, dispatch }, data) {
      commit("loggedin", data);

      // emit connect with cookie token
      state.socket.emit("login", cookie.get("session_token"));

      // other user related stuff
      dispatch("user_requests");
    },

    user_requests({ state, dispatch }) {
      // required requests
      if (state.website.crypto_methods.length === 0) dispatch("load_methods");
      //
    },

    other_requests({ dispatch }) {
      dispatch("load_cases");
    },

    init({ state, commit, dispatch }) {
      // requests to backend that does not need to be logged in
      dispatch("other_requests");
      //

      let sio;
      if (!state.socket) {
        sio = io(state.env.backend_url);
        commit("set_socket", sio);

        // on chat message
        sio.on("chat message", (msg) => {
          dispatch("add_chat_message", msg);
        });

        // on all chat messages
        sio.on("chat messages", (msgs) => {
          dispatch("set_chat_messages", msgs);
        });

        // clear chat
        sio.on("chat clear", () => {
          dispatch("set_chat_messages", []);
        });

        // players online
        sio.on("website online players", (players) => {
          commit("players", players);
        });

        // user listen events
        sio.on("user", (type, data) => {
          console.log("user", type, data);

          if (type === "balance") {
            commit("user", { type: "update_balance", balance: data });
          }

          if (type === "active referrers") {
            commit("set_active_referrers", data);
          }

          if (type === "set_email") {
            // show set email modal
            dispatch("set_modal", {
              modal: "set_email",
              value: true,
            });
          }
        });

        // minesweeper listen events
        sio.on("minesweeper", (type, data) => {
          if (type === "game") {
            dispatch("minesweeper", {
              type: "game",
              data,
            });
          }

          if (type === "reset") {
            dispatch("minesweeper", {
              type: "reset",
            });
          }

          if (type === "round lost") {
            dispatch("minesweeper", {
              type: "round_lost",
              data,
            });
          }

          if (type === "show mines") {
            dispatch("minesweeper", {
              type: "show_mines",
              data,
            });
          }
        });

        // roulette listen events
        sio.on("roulette", (type, data) => {
          commit("roulette", {
            type: "connected",
          });

          if (type === "game") {
            if (data.state === 0) {
              dispatch("roulette", {
                type: "reset",
              });

              commit("roulette", {
                type: "game_info",
                secret: data.secret,
                id: data.id,
              });

              dispatch("roulette", {
                type: "timer",
                seconds: Math.floor(data.seconds / 10),
              });
            }
          }

          if (type === "bet") {
            commit("roulette", {
              type: "bet",
              bet: data,
            });
          }

          if (type === "spin_wheel") {
            if(window.location.pathname.includes('roulette')) playRollSound();

            commit("roulette", {
              type: "prepare_spin",
            });
            dispatch("roulette", {
              type: "spin_wheel",
              outcome: data,
            });
          }

          if (type === "fix_spin_wheel") {
            commit("roulette", {
              type: "prepare_spin",
            });
            dispatch("roulette", {
              type: "fix_spin_wheel",
              outcome: data,
            });
          }

          if (type === "bets") {
            commit("roulette", {
              type: "bets",
              bets: data,
            });
          }

          if (type === "history") {
            commit("roulette", {
              type: "history",
              history: data,
            });
          }

          if (type === "last_rolls") {
            commit("roulette", {
              type: "last_rolls",
              rolls: data,
            });
          }

          if (type === "winning_sound") {
            playWinningSound()
          }
        });

        // alerts:
        sio.on("toast", (type, msg) => {
          Toast(type, msg);
        });
      }
    },
  },
  modules: {},
});

async function backendRequest(type, endpoint, data, method = "post") {
  return new Promise((resolve) => {
    axios[method](
      `${store.state.env.backend_url}/api/${type}${endpoint ? `/${endpoint}` : ''}`,
      data
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve({
          status:
            typeof e?.response?.status === "undefined"
              ? 500
              : e.response.status,
        });
      });
  });
}

export default store;
