import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/tailwind.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faInfoCircle,
  faNoteSticky,
  faCheckCircle,
  faCircleNotch,
  faCircleHalfStroke,
  faBomb,
  faChartLine,
  faRightToBracket,
  faCircle,
  faFileCircleExclamation,
  faPaperPlane,
  faRightFromBracket,
  faSpinner,
  faCoins,
  faPlusSquare,
  faMinusSquare,
  faUserPlus,
  faUser,
  faTrophy,
  faGift,
  faCertificate,
  faBoxOpen,
  faMagnifyingGlass,
  faLifeRing
} from "@fortawesome/free-solid-svg-icons";
library.add({
  faInfoCircle,
  faNoteSticky,
  faCheckCircle,
  faCircleNotch,
  faCircleHalfStroke,
  faBomb,
  faChartLine,
  faRightToBracket,
  faCircle,
  faFileCircleExclamation,
  faPaperPlane,
  faRightFromBracket,
  faSpinner,
  faCoins,
  faPlusSquare,
  faMinusSquare,
  faUserPlus,
  faUser,
  faTrophy,
  faGift,
  faCertificate,
  faBoxOpen,
  faMagnifyingGlass,
  faLifeRing
});

import VueCookies from "vue-cookies";

let app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies, { expires: "7d" });
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
